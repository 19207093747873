const staticImageSectionStyle = {
  background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('https://g4c7y7r6.rocketcdn.me/wp-content/uploads/2023/04/ex.-qr-hotel.webp')`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundColor: "black",
  height: "300px",
  backgroundAttachment: "fixed",
  boxShadow: "0 0 10px rgba(0,0,0,0.5)",

  // Makes the image fixed when scrolling
};
const slideUpVariants = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};
export { staticImageSectionStyle, slideUpVariants };
