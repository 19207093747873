import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Flex,
  Link,
  Image,
  Container,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  useMediaQuery,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { FaGlobe } from "react-icons/fa";
import { HoverStyle, linkStyles } from "./style";
import { useLayoutDirection } from "../../layout/LayoutDireactionContext";
import { getTranslation } from "../../utility/Translation";

const Navigation: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)");
  const { isRTL, toggleLayoutDirection } = useLayoutDirection();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Box bg="black">
      <Container maxW="container.xl">
        <Flex
          as="nav"
          align="center"
          justifyContent="space-between"
          p={4}
          color="white"
        >
          <Flex align="center">
            <Link as={RouterLink} to="/" fontSize="xl" fontWeight="bold">
              <Box bg="white" p={2} ml="5%" mr="5%">
                <Image src="../tkrm.svg" alt="My Logo" width="80px" />
              </Box>
            </Link>
            {isLargerThanMobile && (
              <>
                <Link
                  as={RouterLink}
                  to="/about"
                  ml="1em"
                  mr="1em"
                  fontWeight="semibold"
                  style={linkStyles}
                  _hover={HoverStyle}
                >
                  {getTranslation("about", isRTL)}
                </Link>

                <Link
                  as={RouterLink}
                  style={linkStyles}
                  fontWeight="semibold"
                  to="/terms"
                  ml="1em"
                  mr="1em"
                  _hover={HoverStyle}
                >
                  {getTranslation("terms", isRTL)}
                </Link>
              </>
            )}
          </Flex>

          <Flex align="center">
            <Link
              ml="10%"
              mr="10%"
              fontWeight="semibold"
              style={{
                padding: "0.5rem 1rem",
                borderRadius: "1.5rem",
                display: "flex",
                alignItems: "center", // Center icon and text vertically
              }}
              _hover={HoverStyle}
              onClick={() => {
                toggleLayoutDirection(!isRTL);
                window.location.reload();
              }} // Replace "yourFunction" with the actual function to be called
            >
              <FaGlobe
                style={{ marginRight: "0.5rem", marginLeft: "0.5rem" }}
              />{" "}
              {getTranslation("lang_rtl", !isRTL)}
              {/* {!isRTL ? "EN" : "AR"} */}
            </Link>

            <IconButton
              icon={<HamburgerIcon />}
              aria-label="Open Menu"
              display={{ base: "block", md: "none" }}
              onClick={toggleDrawer}
            />
          </Flex>
        </Flex>
      </Container>

      <Drawer
        isOpen={isDrawerOpen}
        placement="top"
        size={"full"}
        onClose={toggleDrawer}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bg="black"
          >
            <>
              <span style={{ color: "white" }}>Menu</span>
              <IconButton
                icon={<CloseIcon />}
                aria-label="Close Menu"
                onClick={toggleDrawer}
                // color="white"
              />
            </>
          </DrawerHeader>
          <DrawerBody bg="white">
            {!isLargerThanMobile && (
              <>
                <Box m="16px 0">
                  <Link
                    as={RouterLink}
                    to="/about"
                    onClick={toggleDrawer}
                    fontSize="xl"
                    fontWeight="bold"
                  >
                    {getTranslation("about", isRTL)}
                  </Link>
                </Box>

                <Box m="16px 0">
                  <Link
                    as={RouterLink}
                    to="/terms"
                    onClick={toggleDrawer}
                    fontSize="xl"
                    fontWeight="bold"
                  >
                    {getTranslation("terms", isRTL)}
                  </Link>
                </Box>
              </>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Navigation;
