import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  VStack,
  Spinner,
  Card,
  Heading,
  CardBody,
  CardHeader,
  Icon,
  Flex,
  Text,
} from "@chakra-ui/react";
import { FiCheckCircle, FiUserX } from "react-icons/fi";
import { getTranslation } from "../../utility/Translation";
import { useLayoutDirection } from "../../layout/LayoutDireactionContext";
import ReCAPTCHA from "react-google-recaptcha";
import Config from "../../config/config";
import * as Constants from "../../config/constants";
import { DataService } from "../../dataservice/dataService";

function MyForm() {
  const { isRTL } = useLayoutDirection();

  const [formData, setFormData] = useState({
    name: "",
    mobileCountryCode: "966",
    mobile: "",
    company: "",
    role: "",
  });
  const [errors, setErrors] = useState<any>({});
  const [formLoading, setFormLoading] = useState<any>(false);
  const [formSubmitted, setFormSubmitted] = useState<any>(false);
  const [formsubmitFail, setFormsubmitFail] = useState<any>(false);
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

  const handleRecaptchaChange = (value: string | null) => {
    setRecaptchaValue(value);
  };
  const handleChange = (e: any) => {
    const { id, value } = e.target;
    // console.log("handle", id, value);
    setFormData({
      ...formData,
      [id]: value,
    });
  };
  const validation = (event: any) => {
    const isValidKey = /^[0-9]$/.test(event.key);
    const isControlKey =
      event.key === "Backspace" ||
      event.key === "Delete" ||
      event.key === "Tab";

    if (!isValidKey && !isControlKey) {
      event.preventDefault();
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const newErrors: any = {};

    if (formData.name === "") {
      newErrors.name = getTranslation("name_is_required", isRTL);
    }

    if (formData.mobileCountryCode === "" || formData.mobile === "") {
      newErrors.mobile = getTranslation("mobile_number_required", isRTL);
    }

    if (formData.company === "") {
      newErrors.company = getTranslation("company_name_required", isRTL);
    }

    if (formData.role === "") {
      newErrors.role = getTranslation("role_is_required", isRTL);
    }
    if (!recaptchaValue) {
      newErrors.recaptcha = getTranslation("complete_recaptcha", isRTL);
    }
    if (Object.keys(newErrors).length === 0) {
      const bodyParams = {
        name: formData.name,
        mobile: `${formData.mobileCountryCode}${formData.mobile}`,
        company: formData.company,
        role: formData.role,
      };
      let dataBody = {
        ...bodyParams,
        recaptchaResponse: recaptchaValue,
      };
      // Form is valid, you can submit it or perform any other action here
      // console.log("Form is valid. Submitting...", dataBody);
      setFormLoading(true);
      const endPoint = `invoice/email?${Constants.API_KEY}`;
      DataService.post(endPoint, dataBody)
        .then((result: { data: any }) => {
          console.log("sucess");
          setFormLoading(false);
          setFormSubmitted(true);
          setFormsubmitFail(false);
        })
        .catch((error: any) => {
          console.log("err", error);
          setFormLoading(true);
          setFormsubmitFail(true);
        });
    } else {
      // Form is not valid, set the validation errors
      setErrors(newErrors);
    }
  };

  return (
    <Box padding={4}>
      {formLoading ? (
        <VStack spacing={4} height={"500px"}>
          <Spinner margin={"auto"} />
        </VStack>
      ) : formSubmitted ? (
        <VStack spacing={4} height={"500px"}>
          <Flex align="center" justify="center" height="100vh">
            <Card
              textAlign="center"
              align="center"
              style={
                formsubmitFail
                  ? { borderTop: "4px solid red" }
                  : { borderTop: "4px solid teal" }
              }
            >
              <CardHeader>
                <Heading size="md" color={formsubmitFail ? "red" : "teal"}>
                  {formsubmitFail
                    ? "Information Not received"
                    : "Information sent successfully"}
                </Heading>
              </CardHeader>
              <CardBody textAlign="center">
                <Box textAlign="center">
                  <Icon
                    as={formsubmitFail ? FiUserX : FiCheckCircle}
                    w={30}
                    h={10}
                    mb={2}
                    color={formsubmitFail ? "red" : "teal"}
                  />
                  <Text fontWeight={"semibold"}>
                    {formsubmitFail
                      ? "Sorry we are unable to send the form right now, please try again"
                      : "We have received your join request,Our admin will check the details and contact you, please wait for further updates,Thank You"}
                    {formsubmitFail && (
                      <div>
                        <Button
                          onClick={() => {
                            window.location.reload();
                          }}
                        >
                          {" "}
                          click here
                        </Button>
                      </div>
                    )}
                  </Text>
                </Box>
              </CardBody>
            </Card>
          </Flex>
        </VStack>
      ) : (
        <VStack spacing={4}>
          <FormControl id="name">
            <FormLabel>{getTranslation("name", isRTL)}</FormLabel>
            <Input
              type="text"
              placeholder={getTranslation("please_enter_the_name", isRTL)}
              value={formData.name}
              onChange={handleChange}
            />
            {formData.name === "" && errors.name && (
              <Text color="red">{errors.name}</Text>
            )}
          </FormControl>

          <FormControl id="mobile">
            <FormLabel>{getTranslation("mobile_number", isRTL)}</FormLabel>
            <div style={{ display: "flex" }}>
              <Select
                placeholder={getTranslation("select_country_code", isRTL)}
                value={formData.mobileCountryCode}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    mobileCountryCode: e.target.value,
                  })
                }
                width={"90px"}
                margin={"0px 5px"}
                style={{ display: "inline" }}
              >
                <option value="966">+966 (Saudi Arabia)</option>
                <option value="1">+1 (USA)</option>
                <option value="44">+44 (UK)</option>
              </Select>
              <Input
                type="text"
                placeholder={getTranslation("5XXXXXXXX", isRTL)}
                value={formData.mobile}
                onChange={handleChange}
                maxLength={10}
                onKeyDown={(event) => {
                  validation(event);
                }}
                style={{ display: "inline", width: "80%" }}
              />
            </div>

            {formData.mobile === "" && errors.mobile && (
              <Text color="red">{errors.mobile}</Text>
            )}
          </FormControl>

          <FormControl id="company">
            <FormLabel>{getTranslation("company", isRTL)}</FormLabel>
            <Input
              type="text"
              placeholder={getTranslation("enter_your_company", isRTL)}
              value={formData.company}
              onChange={handleChange}
            />
            {formData.company === "" && errors.company && (
              <Text color="red">{errors.company}</Text>
            )}
          </FormControl>
          <FormControl id="role">
            <FormLabel>{getTranslation("your_role_company", isRTL)}</FormLabel>
            <Select
              placeholder={getTranslation("select_your_role", isRTL)}
              value={formData.role}
              onChange={(e) =>
                setFormData({ ...formData, role: e.target.value })
              }
            >
              <option value="owner">{getTranslation("owner", isRTL)}</option>
              <option value="management">
                {getTranslation("management", isRTL)}
              </option>
              <option value="purchasing">
                {getTranslation("purchasing", isRTL)}
              </option>
              <option value="others">{getTranslation("others", isRTL)}</option>
            </Select>
            {formData.role === "" && errors.role && (
              <Text color="red">{errors.role}</Text>
            )}
          </FormControl>
          <ReCAPTCHA
            sitekey={process.env.CAPCTHA_SITE_KEY || Config.CAPCTHA_SITE_KEY}
            onChange={handleRecaptchaChange}
          />
          {recaptchaValue === null && errors.recaptcha && (
            <Text color="red">{errors.recaptcha}</Text>
          )}
          <Button
            color="white"
            backgroundColor="black"
            _hover={{ boxShadow: "0 0 5px 2px rgba(0,0,0,0.4)" }}
            type="submit"
            onClick={handleSubmit}
          >
            {getTranslation("submit", isRTL)}
          </Button>
        </VStack>
      )}
    </Box>
  );
}

export default MyForm;
