import React from "react";
import { Box, Heading, Container } from "@chakra-ui/react";
import { getTranslation } from "../../utility/Translation";
import { useLayoutDirection } from "../../layout/LayoutDireactionContext";
const About: React.FC = () => {
  const { isRTL } = useLayoutDirection();

  return (
    <Container
      maxW="container.md"
      centerContent
      bg="almond"
      minHeight="100vh"
      style={{ padding: "20px" }}
    >
      <Box
        fontWeight="bold"
        fontSize="xl"
        boxShadow="lg"
        p={10}
        borderRadius="xl"
        style={{ borderTop: "5px solid teal" }}
      >
        <Heading>
          <h1>{getTranslation("suffering_is_over", isRTL)}</h1>
        </Heading>
        <Box fontWeight="400" fontSize={"xl"} p={5}>
          {getTranslation("outside_home", isRTL)}
        </Box>
      </Box>
    </Container>
  );
};

export default About;
