import React from "react";
import {
  Box,
  //   Heading,
  Container,
  Card,
  CardBody,
  Image,
  Stack,
} from "@chakra-ui/react";
import { getTranslation } from "../../utility/Translation";
import { useLayoutDirection } from "../../layout/LayoutDireactionContext";
const Store: React.FC = () => {
  const { isRTL } = useLayoutDirection();

  return (
    <Container
      maxW="container.md"
      centerContent
      bg="almond"
      minHeight="100vh"
      style={{ padding: "20px" }}
    >
      <Box
        fontWeight="bold"
        fontSize="xl"
        boxShadow="lg"
        p={10}
        borderRadius="xl"
        style={{ borderTop: "5px solid teal" }}
      >
        <Card maxW="sm">
          <CardBody>
            <Image
              src="../store.png"
              objectFit="cover"
              alt="a person using QR"
              borderRadius="lg"
            />

            <Stack mt="6" spacing="3">
              {/* <Heading>
                <h1>{getTranslation("suffering_is_over", isRTL)}</h1>
              </Heading> */}
              <Box fontWeight="500" fontSize={"xl"} p={5}>
                {getTranslation("access_store", true)}
              </Box>
              <Box fontWeight="500" fontSize={"xl"} p={5}>
                {getTranslation("access_store", false)}
              </Box>
            </Stack>
          </CardBody>
        </Card>
      </Box>
    </Container>
  );
};

export default Store;
