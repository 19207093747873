const titleStyle: any = {
  position: "relative",
};
const underlineStyle: any = {
  content: '""',
  position: "absolute",
  display: "block",
  width: "70px",
  height: "3px",
  background: "#47b2e4",
  bottom: 0,
  left: "calc(50% - 40px)",
};
const underlineStyleForside: any = {
  content: '""',
  position: "absolute",
  display: "block",
  width: "100px",
  height: "3px",
  background: "#47b2e4",
  bottom: 0,
  left: "calc(20% - 10px)",
};
const underlineStyleForsideRight: any = {
  content: '""',
  position: "absolute",
  display: "block",
  width: "100px",
  height: "3px",
  background: "#47b2e4",
  bottom: 0,
  right: "calc(20% - 10px)",
};
const underlineStyleForsideRes: any = {
  content: '""',
  position: "absolute",
  display: "block",
  width: "100px",
  height: "3px",
  background: "#47b2e4",
  bottom: 0,
  left: "calc(7% - 40px)",
};
const underlineStyleForsideResRight: any = {
  content: '""',
  position: "absolute",
  display: "block",
  width: "100px",
  height: "3px",
  background: "#47b2e4",
  bottom: 0,
  right: "calc(7% - 40px)",
};
const slideUpVariants = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};
const slideDownVariants = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};
const slideLeftVariants = {
  hidden: {
    x: -200,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};
// const slideRightVariants = {
//   hidden: {
//     x: 200,
//     opacity: 0,
//   },
//   visible: {
//     x: 0,
//     opacity: 1,
//     transition: {
//       duration: 1,
//     },
//   },
// };
const zoomOutVariants = {
  initial: {
    scale: 1,
  },
  hover: {
    scale: 1.1, // Increase the scale on hover (you can adjust the value)
    transition: {
      duration: 0.3, // Adjust the animation duration as needed
    },
  },
};
const staticImageSectionStyle = {
  background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('https://g4c7y7r6.rocketcdn.me/wp-content/uploads/2023/04/ex.-qr-hotel.webp')`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundColor: "black",
  height: "300px",
  backgroundAttachment: "fixed",
  boxShadow: "0 0 10px rgba(0,0,0,0.5)", // Makes the image fixed when scrolling
};

const staticImageSectionStyle1 = {
  // background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('https://g4c7y7r6.rocketcdn.me/wp-content/uploads/2023/04/ex.-qr-hotel.webp')`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundColor: "white",

  backgroundAttachment: "fixed",
  boxShadow: "0 0 10px rgba(0,0,0,0.5)", // Makes the image fixed when scrolling
};
const staticImageSectionStyle2 = {
  // background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('https://g4c7y7r6.rocketcdn.me/wp-content/uploads/2023/04/ex.-qr-hotel.webp')`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundColor: "#f6f6f6",

  backgroundAttachment: "fixed",
  // boxShadow: "0 0 10px rgba(0,0,0,0.5)", // Makes the image fixed when scrolling
};
export {
  titleStyle,
  zoomOutVariants,
  slideLeftVariants,
  slideDownVariants,
  slideUpVariants,
  underlineStyle,
  underlineStyleForside,
  underlineStyleForsideRes,
  staticImageSectionStyle,
  staticImageSectionStyle1,
  staticImageSectionStyle2,
  underlineStyleForsideResRight,
  underlineStyleForsideRight,
};
