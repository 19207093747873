import React from "react";
import { Box, Heading, Container } from "@chakra-ui/react";

const Terms: React.FC = () => {
  return (
    <Container
      maxW="container.md"
      centerContent
      bg="almond"
      minHeight="100vh"
      style={{ padding: "20px" }}
    >
      <Box>
        <Heading size="md">Privacy Policy </Heading>
        <p>
          TKRM appreciates your concerns about the privacy of your data. This
          policy has been prepared to help you understand what data we collect
          from you when you visit our application and how we treat this data.
          Categories of Personal/User Data we may Process & Collect:
        </p>
        <br />
        <Heading size="md">Personal details:</Heading>
        <p>
          Given name(s); preferred name, and photograph (if provided); that will
          be viewable to users as your account’s name. Contact details: address;
          telephone number; email address; will be collected to authenticate
          your account and keep it - and our services - secure, and to help
          prevent spam, fraud, and abuse. We also use contact information to
          enable certain account features (for example, for login verification),
          to send you information about our services, and to personalize our
          services. If you provide us with your phone number, you agree to
          receive text messages from TKRM to that number as your country’s laws
          allow.
        </p>
        {/* <br />
        <Heading size="md">
          Credit card, debit card, or bank account number
        </Heading>
        <p>
          The user may provide us and the third party with payment information,
          including the  credit or debit card number, card expiration date, CVV
          code, and billing address, in order to make a payment or other
          offerings provided as part of our services. If you make a payment or
          send money using TKRM features or services, including through an
          intermediary, we may receive information about your transaction such
          as when it was made or when a subscription is set to expire or
          auto-renew.
        </p> */}
        <br />
        <Heading size="md">Booking history:</Heading>
        <p>
          User’s booking history inside the app will be viewed by the user and
          no other user nor third party can access this information. The history
          will be stored in the app.
        </p>
        <br />
        <Heading size="md">In-App Chat</Heading>
        <p>
          Users are able to contact the customer service in the in app-chat if
          it was enabled by them. Chat contains any of the following: (photos,
          videos, text, audio files such as voice notes or any other music
          files) All content will not be accessed by anyone other than TKRM. No
          other users can view 2 user’s private chat. In-app chat will be
          captured and not deleted even if the users archived the chat channels.
          The chat channel can be permanently deleted when the user de-actives
          and deleted their account.
        </p>
        <br />
        <Heading size="md">Authentication Information:</Heading>
        <p>
          In order to authenticate the user’s account, an SMS from TKRM will be
          sent to the user. In order to authenticate the user’s phone number, as
          Code via SMS will be sent to the user.Personal data such as the phone
          number and the email will be shared with a third party for logistics
          and processing reasons only, your information will not be stored with
          them, it will be for temporarily use only.
        </p>
        <br />
        <Heading size="md">Microphone & Camera:</Heading>
        <p>
          The microphone can be used in the in-app chat to contact other users
          in the app, the microphone will not be accessed otherwise.Camera can
          be used in the in-app chat to share photos or videos with other users,
          also will be used when taking photos or videos while sharing a post in
          the application. The Camera will not be used nor accessed in other
          cases.Photos and video captured will be restored in the app and will
          not be shared with third parties.
        </p>
        <br />
        <Heading size="md">Location Data:</Heading>
        <p>
          We get the location data from signals such as your IP address or
          device settings User’s Approximate location will be gathered when a
          user shares a post in the app, the precise location of the user will
          not be shared with other users. User’s precise location will be shared
          with the tech third party only.{" "}
        </p>
        <br />
        <Heading size="md">Tracking:</Heading>
        <p>
          The app will track the user’s activity in the app; such as: search
          words or restrooms in app, number opened chat in app.
        </p>
        <br />
        <Heading size="md">Orders</Heading>
        <p>
          Orders will be documented and captured in the app- but not shared with
          any third party nor other users in the app.
        </p>
        <br />
        <Heading size="md">Search history</Heading>
        <p>
          will be captured and documented in order to provide the user a better
          and improved experience, all data in search history will not be shared
          with any third parties. Links that you interact with are kept track of
          in order to operate and improve our services. This includes links that
          are in emails sent by us or shared through our support section.
        </p>
        <br />
        <Heading size="md">Personalizing:</Heading>
        <p>
          We may collect your preferences from your likes, search history, and
          orders sent to provide you a customized better experience.
        </p>
        <br />
        <Heading size="md">Data Protection/Encryption in transit</Heading>
        <p>
          We implement encryption at transit and encryption at rest to protect
          user’s data
        </p>
        <br />
        <Heading size="md">Children</Heading>
        <p>
          TKRM does not knowingly collect, either online or offline, personal
          information from persons under the age of thirteen. If you are under
          18, you may use TKRM only with permission of a parent or guardian, The
          user must also be old enough to consent to the processing of your
          personal data in your country (in some countries we may allow a parent
          or guardian to do so on the child’s behalf).
        </p>
        <br />
        Request data deletion You may ask us to delete or remove your Personal
        Data/Account by contacting us through the app, after deletion applied
        you will be signed out. After that You will have 30 days to restore your
        account by logging back before it gets permanently deleted.
        <br />
        <ol>
          <li>Your orders will no longer be active.</li>
          <li>
            All chat channels will be viewable only by the users in the same
            chat channel.
          </li>
          <li>
            All data for the account will not be viewable by any user but will
            be kept by TKRM.
          </li>
          <li>
            Kindly withdraw all remaining amount in your wallet, after account
            deletion you will not be able to withdraw any amounts. If you
            activate your account, your posts and chat channels will be
            restored, and you will be able to withdraw any remaining amount in
            your wallet. Note: TKRM will keep chat channels, posts, and orders'
            data archived for legality reasons where they will not be accessible
            by any user.
          </li>
        </ol>
        <br />
        <p>
          <strong>Consent records:</strong> records of any consents you may have
          given, together with the date and time, means of consent and any
          related information (e.g., the subject matter of the consent).
          Employer details: where you interact with us in your capacity as an
          employee, the name, address, telephone number and email address of
          your employer, to the extent relevant.
        </p>
        <br />
        <p>
          <strong> Views and opinions: </strong>
          any views and opinions that you choose to send to us. In Processing
          your Personal Data in connection with the purposes set out in this
          Notice, we may rely on one or more of the following legal bases,
          depending on the circumstances:
        </p>
        <br />
        <p>
          <strong>Consent:</strong>
          We may Process your Personal Data where we have obtained your prior,
          express consent to the Processing (this legal basis is only used in
          relation to Processing that is entirely voluntary – it is not used for
          Processing that is necessary or obligatory in any way);
        </p>
        <br />
        <p>
          <strong>Contractual necessity:</strong>
          We may Process your Personal Data where the Processing is necessary in
          connection with any contract that you may enter into with us.
        </p>
        <br />
        <p>
          <strong>Compliance with applicable law:</strong>
          We may Process your Personal Data where the Processing is required by
          applicable law;
        </p>
        <br />
        <p>
          <strong>Vital interests:</strong>
          We may Process your Personal Data where the Processing is necessary to
          protect the vital interests of any individual; or
        </p>
        <br />
        <p>
          <strong>Legitimate interests:</strong>
          We may Process your Personal Data where we have a legitimate interest
          in the Processing. The balancing test we have conducted in each case
          is as follows: we have ensured that the Processing is lawful,
          proportionate, and conducted in accordance with the terms of this
          Notice;  we have ensured that we have a legitimate business need to
          perform the Processing; and we have ensured that there is no material
          likelihood of any adverse impact on your interests, fundamental
          rights, or freedoms, as a result of the Processing.
        </p>
        <br />
        <p>
          Data security:
          <div>
            <ul>
              <li>
                We implement appropriate technical and organizational security
                measures to protect your Personal Data. Please ensure that any
                Personal Data that you send to us is sent securely.
              </li>
              <li>
                We have implemented appropriate technical and organizational
                security measures designed to protect your Personal Data against
                accidental or unlawful destruction, loss, alteration,
                unauthorized disclosure, unauthorized access, and other unlawful
                or unauthorized forms of Processing, in accordance with
                applicable law.
              </li>
              <li>
                Because the internet is an open system, the transmission of
                information via the internet is not completely secure. Although
                we will implement all reasonable measures to protect your
                Personal Data, we cannot guarantee the security of your data
                transmitted to us using the internet – any such transmission is
                at your own risk and you are responsible for ensuring that any
                Personal Data that you send to us are sent securely.
              </li>
            </ul>
          </div>
        </p>
        <br />
        <p>
          Data accuracy: We take every reasonable step to ensure that your
          Personal Data is kept accurate and up-to-date and is erased or
          rectified if we become aware of inaccuracies. We take every reasonable
          step to ensure that: Your Personal Data that we Process are accurate
          and, where necessary, kept up to date; and any of your Personal Data
          that we Process that are inaccurate (having regard to the purposes for
          which they are Processed) are erased or rectified without delay. From
          time to time we may ask you to confirm the accuracy of your Personal
          Data.
        </p>
        <br />
        <p>
          Data minimization: We take every reasonable step to limit the volume
          of your Personal Data that we Process to what is necessary. We take
          every reasonable step to ensure that your Personal Data that we
          Process are limited to the Personal Data reasonably necessary in
          connection with the purposes set out in this Notice.
        </p>
        <br />
        <p>
          Data retention: We take every reasonable step to ensure that your
          Personal Data are only retained for as long as they are needed in
          connection with a lawful purpose. We take every reasonable step to
          ensure that your Personal Data are only Processed for the minimum
          period necessary for the purposes set out in this Notice. The criteria
          for determining the duration for which we will retain your Personal
          Data are as follows:
        </p>
        <br />
        <p>
          Cookies and similar technologies (on our website): We may Process your
          Personal Data by placing or reading Cookies and similar technologies.
          When you visit a site we may place Cookies onto your device, or read
          Cookies already on your device, subject always to obtaining your
          consent, where required, in accordance with applicable law. We use
          Cookies to record information about your device, your browser, and, in
          some cases, your preferences and browsing habits. We may Process your
          Personal Data through Cookies and similar technologies, in accordance
          with our Cookie Policy.
        </p>
        <br />
        <p>
          Direct marketing: We may Process your Personal Data to contact you
          with information regarding services that may be of interest to you.
          You may unsubscribe for free at any time. We may Process your Personal
          Data to contact you via email, mobile phone, direct mail, or other
          communication formats to provide you with information regarding
          services that may be of interest to you. If we provide services to
          you, we may send information to you regarding our services, upcoming
          promotions, and other information that may be of interest to you,
          using the contact details that you have provided to us and always in
          compliance with applicable law. You may unsubscribe from our
          promotional email list at any time by simply clicking on the
          unsubscribe link included in every promotional email we send. After
          you unsubscribe, we will not send you further promotional emails, but
          we may continue to contact you to the extent necessary for the
          purposes of any services you have requested.
        </p>
        <br />
        <p>
          Return and Refund policy: If a customer notices any issue on any of
          our services, the customer shall communicate with TKRM support team in
          writing, and by e-mail for any technical support directly on
          (support@TKRM.co) - TKRM Customer Service. In case the customer is not
          satisfied with the quality of services that were provided to him due
          to a defect in them, which is on the Limitation: (a defect in the
          services or invalidity of the product for use according to the no
          access indicated on it, or unavailability of the confirmed service
          after payment done), and accordingly, the customer is compensated in
          the wallet or bank card after confirming and verifying that there is a
          defect in the product or request by the TKRM team specialist.
        </p>
        <br />
        <ol>
          <li>
            Browsing: Only the data provided by you will be used with your
            knowledge.
          </li>
          <li>
            Internet Protocol (IP) address: The host server automatically - as
            in any other site - records your Internet Protocol (IP) address, the
            date and time of the visit, the type of Internet browser you are
            using, and the URL of any of the Internet sites that refer you to
            this web site.
          </li>
          <li>
            Links to other sites on the Internet: Our site may contain links to
            other sites on the Internet. We are not responsible for those sites;
            you can view the privacy policies and contents of those sites that
            are accessed through any link within this site.
          </li>

          <li>
            The data necessary to carry out the transactions requested by you.
            When any user data is needed, we will ask you to provide it with
            only your approval. This information will assist us in contacting
            you and carrying out your requests wherever possible. The data
            provided by you will never be sold to any third party to market it
            for its benefit without your prior written consent unless this is
            done on the basis that it is part of collective data used for
            statistical purposes and research without including any data that
            may be used to identify you.
          </li>

          <li>
            When you contact us, the data provided by you is treated
            confidentially. Where the forms that are submitted directly on the
            network require the provision of data that will help us in improving
            the site. The data provided by you will be used to respond to all
            your inquiries, comments, or requests by this site or any of its
            affiliate sites.
          </li>

          <li>
            Disclosure of information to any third party: Our customers' data is
            an important part of how we operate. We, therefore, share data only
            as described below and with companies that have security features
            that are, at a minimum, similar to those practices described in the
            Privacy Policy
          </li>

          <li>
            Other parties: To provide our services, we may cooperate with other
            affiliated companies or non-affiliated service providers (such as
            Retail stores to use their restrooms, marketing companies, payment
            processors for online transactions, etc.). These other companies may
            store data in a digital wallet to carry out your transactions so
            that your use of our services is more efficient.
          </li>
        </ol>
        <br />
        <p>
          We make sure that companies do not use the data for other purposes. By
          using the site feature, you consent to the transfer, storage, use, and
          disclosure of data between affiliates and non-affiliated service
          providers wherever they are located. These companies are tightly
          committed to respecting data privacy.
        </p>
        <br />
        <ol>
          <li>
            Marketing and promotions. The data may be used to provide you with
            information about goods and services that may be of interest to you
            as well as to enhance your experience in browsing and using the
            Site, service messages, new features and improvements, special
            offers, and events. We may communicate with you through several
            channels, including but not limited to emails, web notifications,
            postings, phone calls, and application messages.
          </li>

          <li>
            We may allow third parties to use your data: We may provide data to
            advertisers to help them reach our target audience and to enable us
            to comply with our obligations to advertisers (ie: by displaying
            their ads to our target audience).
          </li>
        </ol>
        <br />
        <p>
          In addition, to participate in some of our competitions and
          promotions, you may be asked to provide additional data. For example:
          if you win a competition, you may be asked to provide more personal
          data to confirm your eligibility and provide the prize to you. This
          data is collected by us or by participating sponsors or sellers of
          promotions. Please note that you should review the privacy policies of
          this third party to learn how they use any data they collect.
        </p>
        <br />
        <ol>
          <li>
            Business Transfers: In the event that the company or virtually all
            of our assets are acquired, customer data will be one of the assets
            transferred to the new owner.
          </li>

          <li>
            Protecting our Site and Other Sites: Disclosure of a customer's
            account and personal data is when such disclosure is required to
            comply with laws and investigations to enforce laws and to protect
            the rights, property, or safety of our users or others. This
            includes exchanging data with other companies and organizations for
            a variety of reasons, including fraud protection and credit risk
            reduction.
          </li>

          <li>
            Amendments to the privacy policy and information privacy We reserve
            the right to amend the terms and conditions of the privacy and
            confidentiality policy if necessary and when appropriate. The
            amendments Only will be implemented here or on the main privacy
            policy page, and you will be constantly notified of the data we have
            obtained, how we will use it and who will provide it with this data.
          </li>
        </ol>
        <br />
        <p>
          Finally, we would like to. assure you that  privacy is very important
          matter to us and you can contact us (TKRM) at support@TKRM.co .
        </p>
      </Box>
    </Container>
  );
};

export default Terms;
