const linkStyles = {
  padding: "0.5rem 1rem",
  borderRadius: "1.5rem",
};
const HoverStyle = {
  textDecoration: "none",
  color: "black",
  backgroundColor: "white",
};

export { HoverStyle, linkStyles };
