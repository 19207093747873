import React from "react";
import {
  Box,
  Text,
  Container,
  Image,
  Flex,
  Link,
  HStack,
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import {
  GrTwitter,
  GrInstagram,
  GrFacebookOption,
  GrLinkedin,
} from "react-icons/gr";

const Footer: React.FC = () => {
  return (
    <Box bg="black" color="white" padding={"60px 60px 20px 60px"}>
      <Container maxW="container.xl">
        <Flex align="center" justify="space-between" marginBottom={5}>
          <Link fontSize="xl" fontWeight="bold">
            <Box bg="white" p={2} borderRadius={"10px"}>
              <Image src="../tkrm.svg" alt="My Logo" width="80px" />
            </Box>
          </Link>
          {/* <Flex align="center">
            <Link
              as={RouterLink}
              to="/blog"
              fontWeight="semibold"
              marginRight={4}
            >
              Blog
            </Link>
            <Link
              as={RouterLink}
              to="/news"
              fontWeight="semibold"
              marginRight={4}
            >
              News Room
            </Link>
            <Link as={RouterLink} to="/investors" fontWeight="semibold">
              Investors
            </Link>
          </Flex> */}
        </Flex>
        <Box py={5} color="white">
          {/* <Link as={RouterLink} to="/help" fontWeight="semibold">
            Visit Help center
          </Link> */}
        </Box>

        <a href="mailto:info@tkrm.co">
          <Text>
            <EmailIcon mr={2} /> info@tkrm.co
          </Text>
        </a>
        <Flex justify="space-between" align="center">
          <Text fontSize="sm">&copy; 2023 TKRM. All rights reserved.</Text>
          <HStack spacing={4}>
            {/* Add your social media icons here */}
            <Link href="#" target="_blank" rel="noopener noreferrer">
              <GrTwitter />
            </Link>
            <Link href="#" target="_blank" rel="noopener noreferrer">
              <GrInstagram />
            </Link>
            <Link href="#" target="_blank" rel="noopener noreferrer">
              <GrFacebookOption />
            </Link>
            <Link href="#" target="_blank" rel="noopener noreferrer">
              <GrLinkedin />
            </Link>
            {/* Add more social media icons as needed */}
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
