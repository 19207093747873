import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Navigation from "./pages/Naviagtion/Navigation";
import Footer from "./pages/Footer/Footer";
import Home from "./pages/Home/Home";
import Terms from "./pages/Terms";
import About from "./pages/About/About";
import Help from "./pages/Help/Help";
import Store from "./pages/Store/Store";
// import ApplePay from "./pages/ApplePay";

const App: React.FC = () => {
  return (
    <ChakraProvider>
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/about" element={<About />} />
          <Route path="/store" element={<Store />} />
          {/* <Route path="/help" element={<Help />} /> */}
          <Route path="*" element={<Home />} />
          {/* <Route
            path="/.well-known/apple-developer-merchantid-domain-association.txt"
            element={<ApplePay />}
          /> */}
        </Routes>
        <Footer />
      </Router>
    </ChakraProvider>
  );
};

export default App;
