const config = {
  language: "english",
  topMenu: false,
  rtl: true,
  // AWS_CONFIG_FILE:'/path/to/config/file',
  API_LOCAL_URL: "http://localhost:7000/api/v1/",
  CAPCTHA_SITE_KEY: "6LcLwc4oAAAAAO1CRVjAjgUmlH_uEBbjgtIPIE5F",
  API_KEY: "D80Rq0xJh3XcTFUpB4RhYOGoV56db9kl",
};

export default config;
