import React from "react";
import { motion } from "framer-motion";
import { useLayoutDirection } from "../../layout/LayoutDireactionContext";
import { Box, useMediaQuery } from "@chakra-ui/react";
import { staticImageSectionStyle, slideUpVariants } from "./style";
import { getTranslation } from "../../utility/Translation";

interface ContentProps {
  firstPage: boolean;
  contentVisible: any;
  contentRef: any;
}
const Content: React.FC<ContentProps> = ({
  firstPage,
  contentRef,
  contentVisible,
}) => {
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)");
  const { isRTL } = useLayoutDirection();

  return (
    // <Container
    //   maxW="container.xl"
    //   ref={contentRef}
    //   style={{
    //     position: "absolute",
    //     top: "90%",
    //     left: 0,
    //     right: 0,
    //     margin: "auto",
    //     width: "90%",
    //     opacity: contentVisible ? 1 : 0,
    //     transition: "opacity 0.5s ease-in-out",
    //   }}
    // >
    <Box style={staticImageSectionStyle} p={isLargerThanMobile ? 10 : 5}>
      <motion.div
        initial="hidden"
        animate={contentVisible ? "visible" : "hidden"}
        variants={slideUpVariants}
      >
        {!firstPage ? (
          <Box
            fontWeight="bold"
            fontSize="xl"
            color="white"
            style={{ textShadow: "0 0 10px rgba(0,0,0,0.5)" }}
          >
            <h1>{getTranslation("manage_store", isRTL)}</h1>
            <Box
              fontWeight="400"
              fontSize={isLargerThanMobile ? "xl" : "sm"}
              color="white"
              p={isLargerThanMobile ? 10 : 5}
            >
              {getTranslation("we_have_solution", isRTL)}
            </Box>
          </Box>
        ) : (
          <Box
            fontWeight="bold"
            fontSize="xl"
            color="white"
            style={{ textShadow: "0 0 10px rgba(0,0,0,0.5)" }}
          >
            <h1>{getTranslation("suffering_is_over", isRTL)}</h1>
            <Box
              fontWeight="400"
              fontSize={"xl"}
              color="white"
              p={isLargerThanMobile ? 10 : 5}
            >
              {getTranslation("outside_home", isRTL)}
            </Box>
          </Box>
        )}
      </motion.div>
    </Box>
    // </Container>
  );
};

export default Content;
