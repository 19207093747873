import {
  GrLicense,
  GrObjectGroup,
  GrLock,
  GrDeploy,
  GrFingerPrint,
  GrServerCluster,
} from "react-icons/gr";
// import Out from "../../assets/out.png";
// import Bg from "../../assets/bg.png";
// import Secure from "../../assets/secure.png";
// import Cut from "../../assets/cut.png";
// import Server from "../../assets/server.png";
const content = [
  {
    id: 1,
    title: "acting_awkward",
    body: "no_long_need",
    icon: GrLicense,
  },
  { id: 2, title: "cut_knot", body: "midst_crowd", icon: GrObjectGroup },
  {
    id: 3,
    title: "dignity_preserved",
    body: "find_nearest",
    icon: GrLock,
  },
  {
    id: 4,
    title: "tkrm_application",
    body: "tkrm_complementary",
    icon: GrDeploy,
  },
];
const content1 = [
  {
    id: 1,
    title: "integrated_system",
    body: "tkrm_lock_device",
    icon: GrLicense,
  },
  {
    id: 2,
    title: "easier_operation",
    body: "using_tkrm_system",
    icon: GrObjectGroup,
  },
  {
    id: 3,
    title: "full_pos",
    body: "we_have_system",
    icon: GrLock,
  },
  {
    id: 4,
    title: "we_help_get",
    body: "most_users_of_app",
    icon: GrDeploy,
  },
  {
    id: 5,
    title: "your_privacy_store",
    body: "when_displaying",
    icon: GrFingerPrint,
  },
  {
    id: 6,
    title: "system_with_detail",
    body: "tkrm_control_panel",
    icon: GrServerCluster,
  },
];
const content2 = [
  {
    id: 1,
    title: "complete_control",
    body: "based_on_desires",
    icon: GrLicense,
  },
  {
    id: 2,
    title: "additional_income",
    body: "saudi_arabia_countries",
    icon: GrObjectGroup,
  },
];
export { content, content1, content2 };
