import {
  Box,
  Card,
  Flex,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useMediaQuery,
  // Container,
} from "@chakra-ui/react";
import { FiPackage, FiNavigation } from "react-icons/fi";
import ContentLoader from "../../utility/ContentLoader";
import MyForm from "./Form";
import { motion } from "framer-motion";
import { slideLeftVariants, slideUpVariants } from "./style";
import { getTranslation } from "../../utility/Translation";
import { useLayoutDirection } from "../../layout/LayoutDireactionContext";
import { Link } from "react-router-dom";
interface HomePageProps {
  firstPage: boolean;
  handleChange: (value: boolean) => void;
  loading: boolean;
}
const HomePage: React.FC<HomePageProps> = ({
  firstPage,
  handleChange,
  loading,
}) => {
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)");
  const { isRTL } = useLayoutDirection();

  const tabBackgroundImages = {
    book: "url('https://www.cvent.com/sites/default/files/styles/focus_scale_and_crop_800x450/public/image/2021-08/rsz_1rsz_proxyclick-visitor-management-system-epek7w5eeic-unsplash.webp?h=9c2070a3&itok=zGXiUnJG')",
    add: "url('https://www2.lehigh.edu/sites/www2/files/2023-07/BusinessInnovationBuildin-LehighUniversity-teaser.jpg')",
    // add: "url('https://dev-to-uploads.s3.amazonaws.com/i/8wzmq1fio1rdfkjtqrp9.jpg')",
  };

  return (
    // <Container maxW="container.xl" style={{ position: "relative" }}>
    <motion.div initial="hidden" animate="visible" variants={slideUpVariants}>
      <Box
        backgroundImage={
          // tabBackgroundImages["book"]
          firstPage ? tabBackgroundImages["book"] : tabBackgroundImages["add"]
        } // Replace with the actual image URL
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundColor="grey"
        backgroundRepeat="no-repeat"
        display="flex"
        flexDir={{ base: "column", md: "row" }} // Set column layout for mobile and row layout for desktop
        // borderRadius={"50px"}
      >
        {/** Left Section */}

        <Flex
          flexBasis={{ base: "100%", md: "70%", xl: "50%" }} // Take full width in mobile and half in desktop
          justify="center" // Center the content horizontally
          py={40} // Set the height of the surrounding Box
        >
          <motion.div
            initial="hidden"
            animate="visible"
            variants={slideLeftVariants}
            style={
              isLargerThanMobile
                ? { width: "90%", height: "100%" }
                : { width: "100%", height: "100%" }
            }
          >
            <Card
              width="90%"
              margin={"auto"}
              backgroundColor="white"
              height="100%"
              p={{ base: 4, md: 10 }} // Adjust padding for mobile and desktop
            >
              <Tabs>
                <TabList display="flex" justifyContent="space-around">
                  <Tab
                    ml={4}
                    p={4}
                    color={"black"}
                    onClick={() =>
                      // handleContentChange(tabBackgroundImages["book"])
                      handleChange(true)
                    }
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Icon
                        as={FiNavigation}
                        w={16}
                        h={6}
                        mb={2}
                        color="black"
                      />
                      <Box fontWeight="semibold" width="80%">
                        <span>{getTranslation("book_restroom", isRTL)}</span>
                      </Box>
                    </Box>
                  </Tab>
                  <Tab
                    ml={4}
                    p={4}
                    color={"black"}
                    onClick={() =>
                      // handleContentChange(tabBackgroundImages["add"])
                      handleChange(false)
                    }
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Icon as={FiPackage} w={16} h={6} mb={2} color="black" />

                      <Box fontWeight="semibold" width="80%">
                        <span>
                          {getTranslation("add_restroom_store", isRTL)}
                        </span>
                      </Box>
                    </Box>
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    {loading ? (
                      <ContentLoader />
                    ) : (
                      <>
                        <Box fontWeight="bold" fontSize="xl">
                          {getTranslation("look_near_restroom", isRTL)}
                        </Box>
                        <Box p={5}>
                          <Box>{getTranslation("download_app", isRTL)}</Box>

                          <Box py={3} px={15}>
                            <Link to="https://apps.apple.com/sa/app/tkrm-%D8%AA%D9%83%D8%B1%D9%85/id6468667276">
                              <img
                                src="https://1000logos.net/wp-content/uploads/2020/08/apple-app-store-logo.jpg"
                                alt="appstore"
                                width={"300px"}
                              />
                            </Link>
                          </Box>
                        </Box>
                      </>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {loading ? <ContentLoader /> : <MyForm />}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Card>
          </motion.div>
        </Flex>

        {/** Right Section (for spacing) */}
        <Box ml={4} />
      </Box>
    </motion.div>
    // </Container>
  );
};
export default HomePage;
